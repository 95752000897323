import React, { ReactNode } from 'react'
import { Container } from 'reactstrap'
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';

interface Props {
  children: ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <NavMenu />
      <Container tag="main">
        {children}
      </Container>
      <Footer />
    </div>
  );
};

export default Layout;