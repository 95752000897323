import React, { useEffect, useState } from 'react'
import { updateDocumentHead } from '../utility'
import { useSignalR } from '../hooks/useSignalR'

export default function Home() {
  let title = 'Clara & Philip'
  updateDocumentHead(title)

  const [initialItemData, setInitialItemData] = useState<number>()
  const [loading, setLoading] = useState<boolean>(true)

  const { item } = useSignalR()

  useEffect(() => {
    fetch('api/item')
      .then((response) => response.json())
      .then((data) => {
        setInitialItemData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setLoading(false)
      })
  }, [])

  function getCode(): React.ReactNode {
    switch (item ?? initialItemData) {
      case 1:
        return (
          <>
            <h2 style={{ marginBottom: 100 }}>Kære Clara og Philip</h2>
            <h2 style={{ marginBottom: 200 }}>Hjertelig tillykke med kærligheden ❤️</h2>
          </>
        )
      case 2:
        return (
          <>
            <img className='img-fluid rounded' src='/img/boernehave_philip_pige.webp' />
          </>
        )
      case 3:
        return (
          <>
            <img className='img-fluid rounded mb-5' src='/img/einar.webp' />
          </>
        )
      case 4:
        return (
          <>
            <img className='img-fluid rounded mb-5' src='/img/skolestart2.webp' />
          </>
        )
      case 5:
        return (
          <>
            <img className='img-fluid rounded mb-5' src='/img/skolestart1.webp' />
          </>
        )
      case 6:
        return (
          <>
            <img className='img-fluid rounded mb-5' src='/img/foedselsdag1.webp' />
          </>
        )
      case 7:
        return (
          <>
            <img className='img-fluid rounded mb-5' src='/img/1bklassebillede.webp' />
          </>
        )
      case 8:
        return (
          <>
            <img className='img-fluid rounded mb-5' src='/img/immunforsvar1.webp' />
            <img className='img-fluid rounded mb-5' src='/img/immunforsvar3.webp' />
          </>
        )
      case 9:
        return (
          <>
            <img className='img-fluid rounded mb-5' src='/img/de3opgaver1.webp' />
            <img className='img-fluid rounded mb-5' src='/img/de3opgaver2.webp' />
          </>
        )
      case 10:
        return (
          <>
            <h2 style={{ marginBottom: 50 }}>Kære Clara og Philip</h2>
            <h2 style={{ marginBottom: 50 }}>Hjertelig tillykke med kærligheden ❤️</h2>
            <img className='img-fluid rounded mb-5' src='/img/boernehave_philip_pige.webp' />
            <img className='img-fluid rounded mb-5' src='/img/einar.webp' />
            <img className='img-fluid rounded mb-5' src='/img/skolestart2.webp' />
            <img className='img-fluid rounded mb-5' src='/img/skolestart1.webp' />
            <img className='img-fluid rounded mb-5' src='/img/foedselsdag1.webp' />
            <img className='img-fluid rounded mb-5' src='/img/1bklassebillede.webp' />
            <img className='img-fluid rounded mb-5' src='/img/immunforsvar1.webp' />
            <img className='img-fluid rounded mb-5' src='/img/immunforsvar3.webp' />
            <img className='img-fluid rounded mb-5' src='/img/de3opgaver1.webp' />
            <img className='img-fluid rounded mb-5' src='/img/de3opgaver2.webp' />
            <h2 style={{ marginBottom: 50, marginTop: 50 }}>Ekstra materiale</h2>
            <img className='img-fluid rounded mb-5' src='/img/vulkan1.webp' />
            <img className='img-fluid rounded mb-5' src='/img/vulkan2.webp' />
            <img className='img-fluid rounded mb-5' src='/img/zoo.webp' />
            <img className='img-fluid rounded mb-5' src='/img/boernehave.webp' />
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      {loading ? (
        <center>
          <div className='spinner-border' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </center>
      ) : (
        <center>{getCode()}</center>
      )}
    </>
  )
}
