import React from 'react'
import { updateDocumentHead } from '../utility'

export default function Panic() {
  let title = 'Clara & Philip'
  updateDocumentHead(title)

  return (
    <center>
      <h2 style={{ marginBottom: 50 }}>Kære Clara og Philip</h2>
      <h2 style={{ marginBottom: 50 }}>Hjertelig tillykke med kærligheden ❤️</h2>
      <img className='img-fluid rounded mb-5' src='/img/boernehave_philip_pige.webp' />
      <img className='img-fluid rounded mb-5' src='/img/einar.webp' />
      <img className='img-fluid rounded mb-5' src='/img/skolestart2.webp' />
      <img className='img-fluid rounded mb-5' src='/img/skolestart1.webp' />
      <img className='img-fluid rounded mb-5' src='/img/foedselsdag1.webp' />
      <img className='img-fluid rounded mb-5' src='/img/1bklassebillede.webp' />
      <img className='img-fluid rounded mb-5' src='/img/immunforsvar1.webp' />
      <img className='img-fluid rounded mb-5' src='/img/immunforsvar3.webp' />
      <img className='img-fluid rounded mb-5' src='/img/de3opgaver1.webp' />
      <img className='img-fluid rounded mb-5' src='/img/de3opgaver2.webp' />
    </center>
  )
}
