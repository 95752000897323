import React, { useEffect, useState } from 'react'
import { updateDocumentHead } from '../utility'
import { useSignalR } from '../hooks/useSignalR'

export default function Host() {
  let title = 'Remote - Clara & Philip'
  updateDocumentHead(title)

  const [itemData, setItemData] = useState<number>()
  const [loading, setLoading] = useState<boolean>(true)

  const { sendItem } = useSignalR()

  useEffect(() => {
    fetch('api/item')
      .then((response) => response.json())
      .then((data) => {
        setItemData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setLoading(false)
      })
  }, [])

  function updateItem(newItemNumber: number) {
    fetch('api/item', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newItemNumber)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
      })
      .then(() => {
        sendItem(newItemNumber)
        setItemData(newItemNumber)
      })
      .catch((error) => {
        console.error('Error updating item:', error)
      })
  }

  return (
    <>
      <center>
        <h1>Remote</h1>
        <h2>Current value: {!loading && itemData}</h2>
      </center>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>Introduktion</h2>
            <ul>
              <li>Tillykke til Clara og Philip med jeres kærlighed! Skål for jer og det flotte arrangement!</li>
              <li>Tale om vores venskab.</li>
              <li>Første gang jeg mødte Philip.</li>
              <li>Memory lane: Jeg vil gerne vise nogle billeder, men der er ingen projektor.</li>
              <li>Alle har en telefon – så det kan vi bruge!</li>
              <li>Mit arbejde som ingeniør er ikke blevet anerkendt i vores vennegruppe.</li>
              <li>Philip og Falster-gutterne driller mig med ingeniørtitlen.</li>
              <li>Jeg har derfor lavet en løsning: et interaktivt show via claraogphilip.dk.</li>
              <li>Alle bedes tage deres telefoner frem og gå ind på hjemmesiden.</li>
              <li>Jeg styrer præsentationen med en fjernbetjening – I skal bare følge med og kigge op og ned.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(1)}>
              Hjertelig tillykke tekst
            </button>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>Børnehave Philip ved siden af pige</h2>
            <ul>
              <li>Vi starter med et billede fra børnehaven.</li>
              <li>Dengang vi var små og uskyldige.</li>
              <li>Philip nede til højre og jeg oppe til højre.</li>
              <li>Kan I huske den alder hvor drenge synes piger er lidt ulækre?</li>
              <li>Philip sidder på en balle.</li>
              <li>I dag beviser at du kom over det stadie.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(2)}>
              Børnehave Philip ved siden af pige
            </button>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>Guldkorn</h2>
            <ul>
              <li>Børn siger sjove ting.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(3)}>
              Ejner guldkorn
            </button>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>Skolestart</h2>
            <ul>
              <li>Startede også samtidig i skole.</li>
              <li>Ikke til at skille ad.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(4)}>
              Skolestart Philip og jeg
            </button>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>Skolestart 2</h2>
            <ul>
              <li>Søde og små knægte.</li>
              <li>Igen ved siden af hinanden.</li>
              <li>Ville hellere spille Pokemon.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(5)}>
              Skolestart gruppebillede
            </button>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>Børnefødselsdag</h2>
            <ul>
              <li>Det samme galdte børnefødselsdag.</li>
              <li>Holdte sodavand sammen for whatever reason.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(6)}>
              Børnefødselsdag
            </button>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>1. klassebillede (Philip sur)</h2>
            <ul>
              <li>Der var dårlig stemning når vi ikke kunne være ved siden af hinanden.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(7)}>
              1. klassebillede (Philip sur)
            </button>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>Immunforsvar rapport</h2>
            <ul>
              <li>Tidlige tegn på at Philip skulle være læge.</li>
              <li>Scroll down: Logbog, Fredag.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(8)}>
              Immunforsvar rapport
            </button>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>De 3 opgaver</h2>
            <ul>
              <li>2. klasse eventyrsamling med hele klassen.</li>
              <li>Lidt sjovt at Philip skrev et eventyr om et bryllup.</li>
              <li>... med fucking Søren. Forræder.</li>
              <li>(Jeg var syg og ikke med i samlingen).</li>
              <li>Oplæsning.</li>
              <li>Philip du er både klog, modig og stærk. I sær klog fordi du valgte Clara.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(9)}>
              De 3 opgaver
            </button>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <h2>Vis alt</h2>
            <ul>
              <li>Stort tillykke til jer begge.</li>
              <li>Udråbe et trefoldigt hurra!</li>
              <li>Al materiale er tilgængeligt nu på siden plus ekstra materiale.</li>
              <li>Skål!</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container mb-1'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button type='button' className='btn btn-success p-4' onClick={() => updateItem(10)}>
              Vis alt
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
