import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    const year = new Date().getFullYear()

    return (
      <div className={'container'}>
        <footer className={'d-flex flex-wrap justify-content-center align-items-center py-3 my-4 border-top'}>
          <p className={'col-md-4 mb-0 text-muted text-center'}>© ClaraOgPhilip.dk - {year}</p>
        </footer>
      </div>
    )
  }
}
