import React, { Component } from 'react'
import { Navbar, NavbarBrand } from 'reactstrap'
import { Link } from 'react-router-dom'

interface Props {
  collapsed: boolean
}

export class NavMenu extends Component<{}, Props> {
  static displayName = NavMenu.name

  constructor(props: {}) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.closeNavbar = this.closeNavbar.bind(this)
    this.state = {
      collapsed: true
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  closeNavbar() {
    this.setState({
      collapsed: true
    })
  }

  render() {
    return (
      <Navbar className='navbar navbar-expand-lg navbar-light bg-white py-3 mb-5 justify-content-center' container light>
        <NavbarBrand tag={Link} to='/' className='fw-bolder text-cph mx-auto' onClick={this.closeNavbar}>
          <h1>👰🤵💒</h1>
        </NavbarBrand>
      </Navbar>
    )
  }
}
