import React from 'react'
import Home from './pages/Home'
import Host from './pages/Host'
import Panic from './pages/Panic'

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/host',
    element: <Host />
  },
  {
    path: '/panik',
    element: <Panic />
  }
]

export default AppRoutes
