import { useState, useEffect, useCallback } from 'react'
import * as signalR from '@microsoft/signalr'

export const useSignalR = () => {
  const [item, setItem] = useState<number>()
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null)
  const method = 'broadcastItem'
  const url = process.env.REACT_APP_SIGNALR_URL

  if (!url) {
    throw new Error('Connection string to SignalR unexpectedly undefined')
  }

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build()

    setConnection(newConnection)
  }, [url])

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on(method, (item: number) => {
            setItem(item)
          })
        })
        .catch((e) => console.error('Connection failed: ', e))
    }
  }, [connection, method])

  const sendItem = useCallback(
    async (item: number) => {
      if (connection && connection.state === signalR.HubConnectionState.Connected) {
        try {
          await connection.invoke(method, item)
        } catch (e) {
          console.error('Sending message failed: ', e)
        }
      } else {
        console.warn('Connection is not established. Unable to send message.')
      }
    },
    [connection]
  )

  return { item, sendItem }
}
